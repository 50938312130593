import React from "react";
import { motion } from "framer-motion";
import LinkButton from "../LinkButton";

import "./index.css";

export const MainInfo = () => {
  return (
    <motion.div
      initial={{ opacity: 0.01 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ delay: 0.41 }}
      id="mainDiv"
    >
      <aside>
        <motion.img
          whileHover={{ scale: 1.02 }}
          src="https://avatars.githubusercontent.com/u/61596432?s=400&u=dae5fbe187036b7bdb52d66a96220eb281e59a42&v=4"
          alt="Foto de Giovani"
        />
      </aside>
      <motion.section>
        <h2>
          Oi, Sou o Giovani e sou um <b>Desenvolvedor Full Stack</b>.
        </h2>
        <p>
          Apaixonado por desenvolvimento e sempre pronto para desafios. Adoro
          aprender e aprimorar minhas habilidades e conhecimentos, trabalhar em
          equipe e cumprir prazos com eficiência.
        </p>
        <div id="buttons">
          <LinkButton
            backgroundColor={"rgba(81, 94, 161, 1)"}
            color="white"
            href="https://br.linkedin.com/in/giovani-merces"
            title={"Entre em contato"}
          />
          <LinkButton
            backgroundColor={"white"}
            color={"black"}
            href="https://github.com/Merces-dev"
            title={"Olhe meu trabalho"}
          />
        </div>
      </motion.section>
    </motion.div>
  );
};
