import React, { useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";

import "./index.css";
import { motion } from "framer-motion";

interface NavItem {
  title: string;
  route: string;
}

export const Header = () => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  const navItems: NavItem[] = [
    { title: "Habilidades", route: "#skills" },
    { title: "Projetos", route: "#projs" },
    // { title: "Experiência", route: "#xp" },
    // { title: "Contatos", route: "#contacts" },
  ];

  const handleMenuClick = () => {
    var menu: any = document.getElementById("menu");
    const computedStyles = window.getComputedStyle(menu);

    if (!menu) return;
    if (computedStyles.position === "absolute") {
      if (computedStyles.display === "flex") {
        menu.style.display = "none";
      } else {
        menu.style.display = "flex";
      }
    }
  };
  const handleMouseEnter = (title: string) => {
    setHoveredItem(title);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  function onClickLink(route: string) {
    handleMenuClick();
    window.location.href = route;
  }

  return (
    <header>
      <div className="title">
        <a href="/">
          <h1>
            <b>Giovani</b>Merces
          </h1>
        </a>
      </div>
      <nav id="menu">
        <ul>
          {navItems.map((i: NavItem) => (
            <li key={i.title}>
              <button
                onClick={() => {
                  onClickLink(i.route);
                }}
                onMouseEnter={() => handleMouseEnter(i.title)}
                onMouseLeave={handleMouseLeave}
              >
                {i.title}
              </button>
              <motion.div
                initial={{ width: "0%" }}
                animate={hoveredItem === i.title ? { width: "100%" } : {}}
                transition={{ duration: 1 }}
              ></motion.div>
            </li>
          ))}
        </ul>
      </nav>
      <button id="menuButton" onClick={handleMenuClick}>
        <BiMenuAltRight id="menuIcon" />
      </button>
    </header>
  );
};
