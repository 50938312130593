import { motion } from "framer-motion";

interface ButtonProps {
  color: string;
  backgroundColor: string;
  href: string;
  title: string;
}

const LinkButton: React.FC<ButtonProps> = ({backgroundColor, color, href, title }) => {
  return (
    <motion.a
      whileHover={{ scale: 1.01 }}
      whileTap={{ scale: 0.9 }}
      style={{
        backgroundColor: backgroundColor,
        color: color,
        padding: "10px 20px",
        margin: "10px 10px 0px 0px",
        borderRadius: "5px",
        cursor: "pointer",
      }}
      href={href}
    >
      {title}
    </motion.a>
  );
};

export default LinkButton;
