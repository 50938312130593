import React from "react";
import { motion } from "framer-motion";

import Pasio from "../../utils/images/worked-projects/pasio.png";
import { Background } from "../../components/Background";
import "./index.css";

import { CardProps } from "../../components/Card";
import { Carousel } from "../../components/Carousel";
import { MainInfo } from "../../components/MainInfo";
import { Skills } from "../../components/Skills";

export const Home = () => {
  const projects: CardProps[] = [
    {
      title: "InteliRisk",
      image: 'https://www.intelitrader.com.br/assets/intelirisk-1.png',
      description: "Sistemas de risco pré e pós trade flexível",
      route: "/projeto-1",
      tech: ["React", "Dotnet", "Docker"],
    },
    {
      title: "InteliData",
      image: 'https://www.intelitrader.com.br/assets/intelidata-funil.png',
      description: "Concentrador de informações do mercado financeiro",
      route: "/projeto-2",
      tech: ["Dotnet", "Docker"],
    },
    {
      title: "Crefisa+ Empresas",
      image: 'https://www.crefisa.com.br/wp-content/uploads/2020/10/300_x_350px_Banner_Crefisa_CART%C3%83O-Multiplo2.png',
      description: "App Crefisa para Empresas",
      route: "/projeto-3",
      tech: ["React", "TypeScript", "Java", "Objective-C", "Node"],
    },
    {
      title: "Crefisa+",
      image: 'https://www.crefisa.com.br/wp-content/uploads/2020/10/300_x_350px_Banner_Crefisa_CART%C3%83O-Multiplo2.png',
      description: "App Crefisa para pessoas físicas",
      route: "/projeto-4",
      tech: ["React", "TypeScript", "Java", "Objective-C", "Node"],
    },
    {
      title: "Site Pasio Consultoria",
      image: Pasio,
      description: "Projeto web para consultoria",
      route: "/projeto-5",
      tech: ["React", "Firebase"],
    }
  ];

  return (
    <div className="home">
      <Background>
        <div>
          <motion.main
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <MainInfo />

            <div id="skills" className="center">
              <Skills />
            </div>
            <div id="projs" className="center">
              <Carousel title="Projetos trabalhados" items={projects} />
            </div>
          </motion.main>
          <footer></footer>
        </div>
      </Background>
    </div>
  );
};
