import React, { ReactNode } from "react";
import { motion } from "framer-motion";

import "./index.css";

export interface SkillProps {
  title: string;
  icon: ReactNode;
  color: string;
}

const Skill = ({ title, icon, color }: SkillProps) => {
  return (
    <motion.div
      id="skill"
      whileHover={{
        scale: 1.1,
        transition: { duration: 1 },
      }}
    >
      {icon}
      <p>{title}</p>
    </motion.div>
  );
};

export default Skill;
