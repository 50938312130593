import React from "react";
import "./index.css";
import { Header } from "../Header";
export const Background = ({ children }: any) => {
  return (
    <div className="background">
      <Header />
      {children}
    </div>
  );
};
