import React from "react";

import { motion } from "framer-motion";
import "./index.css";
export interface CardProps {
  title: string;
  image: string;
  description: string;
  route: string;
  tech: string[];
}

export const Card = ({ title, image, description, route, tech }: CardProps) => {
  return (
    <motion.div
      initial={{ opacity: 0.01 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ delay: 0.07 }}
      id="card">
      <div id="imageCard">
        <img src={image} alt={`Foto do Projeto ${title}`} />
      </div>
      <div id="titleCard">{title}</div>
      <div id="descriptionCard">{description}</div>
    </motion.div>
  );
};
