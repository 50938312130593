import React from "react";
import "./index.css";
import { motion } from "framer-motion";
import Skill, { SkillProps } from "../Skill";
import {
  BiLogoCss3,
  BiLogoJavascript,
  BiLogoReact,
  BiLogoTypescript,
} from "react-icons/bi";
import { SiCsharp } from "react-icons/si";
import { BsFiletypeSql } from "react-icons/bs";

export const Skills = () => {
  const skills: SkillProps[] = [
    {
      icon: <BiLogoReact color="#61DBFB" className="icon-skills" />,
      title: "React",
      color: "#61DBFB",
    },
    {
      icon: <BiLogoReact color="#61DBFB" className="icon-skills" />,
      title: "React Native",
      color: "#61DBFB",
    },
    {
      icon: <SiCsharp color="#044F88" className="icon-skills" />,
      title: "C#",
      color: "#044F88",
    },
    {
      icon: <BiLogoCss3 color="#264de4" className="icon-skills" />,
      title: "CSS",
      color: "#264de4",
    },
    {
      icon: <BiLogoJavascript color="#F0DB4F" className="icon-skills" />,
      title: "JavaScript",
      color: "#F0DB4F",
    },
    {
      icon: <BiLogoTypescript color="#F0DB4F" className="icon-skills" />,
      title: "TypeScript",
      color: "#F0DB4F",
    },
    {
      icon: <BsFiletypeSql color="#f89820" className="icon-skills" />,
      title: "",
      color: "#f89820",
    },
  ];
  return (
    <div className="skills-wrapper center paddingTopic">
      <div className="skills center">
        <h3>Habilidades</h3>
        <div className="skills-items center">
          <motion.div className="skills-inner center">
            {skills.map((i) => (
              <Skill key={i.title} {...i} />
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};
