import React, { useRef, useEffect } from "react";
import "./index.css";
import { Card, CardProps } from "../Card";
import { motion, useMotionValue } from "framer-motion";

interface CarouselProps {
  title: string;
  items: CardProps[];
}

export const Carousel = ({ title, items }: CarouselProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const scrollX = useMotionValue(0);
  let dragStartX = 0;
  let isDragging = false;

  const handleDragStart = (event: React.MouseEvent) => {
    dragStartX = event.clientX;
    isDragging = true;
  };

  const handleDragMove = (event: React.MouseEvent) => {
    if (!isDragging) return;

    const dragX = event.clientX;
    const dragDistance = dragX - dragStartX;

    if (ref.current) {
      ref.current.scrollLeft -= dragDistance;
    }

    dragStartX = dragX;
  };

  const handleDragEnd = () => {
    isDragging = false;
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollLeft = 0;
    }
  }, []);

  return (
    <div className="carousel-background center ">
      <div className="carousel-wrapper center paddingTopic">
        <div
          className="carousel center"
          onMouseDown={handleDragStart}
          onMouseMove={handleDragMove}
          onMouseUp={handleDragEnd}
          onMouseLeave={handleDragEnd}
        >
          <h3>{title}</h3>
          <div className="carousel-items" ref={ref}>
            <motion.div className="carousel-inner" style={{ x: scrollX }}>
              {items.map((i) => (
                <Card key={i.route} {...i} />
              ))}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};
