import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Home } from "./pages/Home";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCETe-fof-D05sCGVNCYkDIPEpURyJa44s",
  authDomain: "portfolio-10b6a.firebaseapp.com",
  projectId: "portfolio-10b6a",
  storageBucket: "portfolio-10b6a.appspot.com",
  messagingSenderId: "784170572873",
  appId: "1:784170572873:web:435667420e816d7fb3b398",
  measurementId: "G-9MS6S6N6EP"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Home />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
